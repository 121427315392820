<template>
<div>

	    <section class="ftco-facts img ftco-counter bg_3">
			<div class="overlay"></div>
			<div class="container">
				<div class="row d-flex align-items-center">
					<div class="col-md-5 heading-section heading-section-white">
						<span class="subheading">Fun facts</span>
						<h2 class="mb-4">Over 5,100 patients trust us</h2>
						<p class="mb-0"><a href="#service-section" class="btn btn-secondary px-4 py-3">Make an appointment</a></p>
					</div>
					<div class="col-md-7">
						<div class="row pt-4">
		          <div class="col-md-6 d-flex justify-content-center counter-wrap">
		            <div class="block-18">
		              <div class="text">
		                <strong class="number" data-number="30">1+</strong>
		                <span>Years of Experienced</span>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-6 d-flex justify-content-center counter-wrap">
		            <div class="block-18">
		              <div class="text">
		                <strong class="number" data-number="4500">99+</strong>
		                <span>Happy Patients</span>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-6 d-flex justify-content-center counter-wrap">
		            <div class="block-18">
		              <div class="text">
		                <strong class="number" data-number="84">4+</strong>
		                <span>Number of Doctors</span>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-6 d-flex justify-content-center counter-wrap">
		            <div class="block-18">
		              <div class="text">
		                <strong class="number" data-number="300">9+</strong>
		                <span>Number of Staffs</span>
		              </div>
		            </div>
		          </div>
	          </div>
					</div>
				</div>
			</div>
		</section>
<section class="ftco-section" id="doctor-section">
			<div class="container-fluid px-5">
				<div class="row justify-content-center mb-5 pb-2">
          <div class="col-md-8 text-center heading-section ">
            <h2 class="mb-4">Our Qualified Doctors</h2>
            <p>Our team of highly qualified doctors is dedicated to providing exceptional medical care, backed by expertise and compassion, ensuring your well-being is our top priority.</p>
          </div>
        </div>	
				<div class="row">
					<div class="col-md-6 col-lg-3 ">
						<div class="staff">
							<div class="img-wrap d-flex align-items-stretch">
								<div class="img align-self-stretch Lloyd"></div>
							</div>
							<div class="text pt-3 text-center">
								<h3 class="mb-2">Dr. Lloyd Wilson</h3>
								<span class="position mb-2">Neurologist</span>
								<div class="faded">
									<p>A distinguished neurologist, specializes in delivering expert care.</p>
									<ul class="ftco-social text-center">
		                <!-- <li class=""><a href="#"><span class="icon-twitter"></span></a></li> -->
		                <!-- <li class=""><a href="#"><span class="icon-facebook"></span></a></li> -->
		                <li class=""><a href="https://instagram.com/_u/softpital"><span class="icon-instagram"></span></a></li>
		              </ul>
		              <!-- <p><a href="#" class="btn btn-primary">Book now</a></p> -->
	              </div>
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-3 ">
						<div class="staff">
							<div class="img-wrap d-flex align-items-stretch">
								<div class="img align-self-stretch Rachel"></div>
							</div>
							<div class="text pt-3 text-center">
								<h3 class="mb-2">Dr. Rachel Parker</h3>
								<span class="position mb-2">Ophthalmologist</span>
								<div class="faded">
									<p>Your dedicated ophthalmologist for optimal vision care.</p>
									<ul class="ftco-social text-center">
		                <!-- <li class=""><a href="#"><span class="icon-twitter"></span></a></li> -->
		                <!-- <li class=""><a href="#"><span class="icon-facebook"></span></a></li> -->
		                <li class=""><a href="https://instagram.com/_u/softpital"><span class="icon-instagram"></span></a></li>		              </ul>
		              <!-- <p><a href="#" class="btn btn-primary">Book now</a></p> -->
	              </div>
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-3 ">
						<div class="staff">
							<div class="img-wrap d-flex align-items-stretch">
								<div class="img align-self-stretch Doctor-next-div" ></div>
							</div>
							<div class="text pt-3 text-center">
								<h3 class="mb-2">Dr. Ian Smith</h3>
								<span class="position mb-2">Dentist</span>
								<div class="faded">
									<p>Committed to delivering exceptional oral health care with precision.</p>
									<ul class="ftco-social text-center">
		                <!-- <li class=""><a href="#"><span class="icon-twitter"></span></a></li> -->
		                <!-- <li class=""><a href="#"><span class="icon-facebook"></span></a></li> -->
		                <li class=""><a href="https://instagram.com/_u/softpital"><span class="icon-instagram"></span></a></li>		              </ul>
		              <!-- <p><a href="#" class="btn btn-primary">Book now</a></p> -->
	              </div>
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-3 ">
						<div class="staff">
							<div class="img-wrap d-flex align-items-stretch">
								<div class="img align-self-stretch Alicia"></div>
							</div>
							<div class="text pt-3 text-center">
								<h3 class="mb-2">Dr. Alicia Henderson</h3>
								<span class="position mb-2">Pediatrician</span>
								<div class="faded">
									<p>Committed to ensuring the health and happiness of children.</p>
									<ul class="ftco-social text-center">
		                <!-- <li class=""><a href="#"><span class="icon-twitter"></span></a></li> -->
		                <!-- <li class=""><a href="#"><span class="icon-facebook"></span></a></li> -->
		                <li class=""><a href="https://instagram.com/_u/softpital"><span class="icon-instagram"></span></a></li>		              </ul>
		              <!-- <p><a href="#" class="btn btn-primary">Book now</a></p> -->
	              </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
 <section class="ftco-section bg-light" id="blog-section" hidden>
      <div class="container">
        <div class="row justify-content-center mb-5 pb-5">
          <div class="col-md-10 heading-section text-center">
            <h2 class="mb-4">Gets Every Single Updates Here</h2>
            <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia</p>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-md-4">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20 image_1">
              </a>
              <div class="text d-block">
              	<div class="meta mb-3">
                  <div><a href="#">June 9, 2019</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Scary Thing That You Don’t Get Enough Sleep</a></h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                <p><a href="blog-single.html" class="btn btn-primary py-2 px-3">Read more</a></p>
              </div>
            </div>
        	</div>

        	<div class="col-md-4">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20 image_2">
              </a>
              <div class="text d-block">
              	<div class="meta mb-3">
                  <div><a href="#">June 9, 2019</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Scary Thing That You Don’t Get Enough Sleep</a></h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                <p><a href="blog-single.html" class="btn btn-primary py-2 px-3">Read more</a></p>
              </div>
            </div>
        	</div>

        	<div class="col-md-4">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20 image_3">
              </a>
              <div class="text d-block">
              	<div class="meta mb-3">
                  <div><a href="#">June 9, 2019</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Scary Thing That You Don’t Get Enough Sleep</a></h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                <p><a href="blog-single.html" class="btn btn-primary py-2 px-3">Read more</a></p>
              </div>
            </div>
        	</div>

        	<div class="col-md-4">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20 image_4">
              </a>
              <div class="text d-block">
              	<div class="meta mb-3">
                  <div><a href="#">June 9, 2019</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Scary Thing That You Don’t Get Enough Sleep</a></h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                <p><a href="blog-single.html" class="btn btn-primary py-2 px-3">Read more</a></p>
              </div>
            </div>
        	</div>

        	<div class="col-md-4">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20 image_5">
              </a>
              <div class="text d-block">
              	<div class="meta mb-3">
                  <div><a href="#">June 9, 2019</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Scary Thing That You Don’t Get Enough Sleep</a></h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                <p><a href="blog-single.html" class="btn btn-primary py-2 px-3">Read more</a></p>
              </div>
            </div>
        	</div>

        	<div class="col-md-4">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20 image_6">
              </a>
              <div class="text d-block">
              	<div class="meta mb-3">
                  <div><a href="#">June 9, 2019</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Scary Thing That You Don’t Get Enough Sleep</a></h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                <p><a href="blog-single.html" class="btn btn-primary py-2 px-3">Read more</a></p>
              </div>
            </div>
        	</div>
        </div>
      </div>
    </section>


  <section class="ftco-section testimony-section img afterBlog">
    	<div class="overlay"></div>
      <div class="container">
        <div class="row justify-content-center pb-3">
          <div class="col-md-7 text-center heading-section heading-section-white ">
          	<span class="subheading">Read testimonials</span>
            <h2 class="mb-4">Our Patient Says</h2>
          </div>
        </div>
        <div class="row  justify-content-center">
          <div class="col-md-12">
            <div class="carousel-testimony" style="display:flex">
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img afterBlog1">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4">
                    <p class="mb-4">Dr. Alicia Henderson's expertise and warmth made my child's visit comfortable and reassuring. Highly recommend her for pediatric care.<br/></p>
                    <p class="name">Pierre Dubois</p>
                    <span class="position">Patients</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img afterBlog2">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4">
                    <p class="mb-4">Dr. Alicia Henderson's compassionate approach and exceptional expertise truly made a positive impact on my child's health journey.<br/></p>
                    <p class="name">Sophie Martin</p>
                    <span class="position">Patients</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img afterBlog3">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4">
                    <p class="mb-4">Dr. Rachel Parker's thoroughness and professionalism were evident in every aspect of my consultation. His expertise and reassuring demeanor instilled confidence.</p>
                    <p class="name">François Leclerc</p>
                    <span class="position">Patients</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img afterBlog1">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4">
                    <p class="mb-4">Dr. Ian Smith's exceptional care and understanding truly eased our worries during our visit. Her dedication to pediatric medicine are commendable.</p>
                    <p class="name">Camille Dupont</p>
                    <span class="position">Patients</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap text-center py-4 pb-5">
                  <div class="user-img afterBlog3">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text px-4">
                    <p class="mb-4">Dr. Alicia's care and expertise provided exceptional comfort during my child's visit. Highly recommended for pediatric needs.</p>
                    <p class="name">Élise Laurent</p>
                    <span class="position">Patients</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

 
</div>
</template>

<script>
export default {
  name: 'Doctor',
  
}
</script>
<style>
.afterBlog{
	background-image: url('../assets/images/bg_3.jpg');
}
.afterBlog1{
	background-image: url('../assets/images/person_1.jpg');
}
.afterBlog2{
	background-image: url('../assets/images/person_2.jpg');
}
.afterBlog3{
	background-image: url('../assets/images/person_3.jpg');
}

.Doctor-next-div{
background-image: url('../assets/images/doc-3.jpg');
}
.Alicia{
	background-image: url('../assets/images/doc-4.jpg');
}
.Rachel {
	background-image: url('../assets/images/doc-2.jpg');
}
.Lloyd{
	background-image: url('../assets/images/doc-1.jpg');
}
.bg_3{
	background-image: url('../assets/images/bg_3.jpg');
}
.image_1{
	background-image: url('../assets/images/image_1.jpg');
}
.image_2{
	background-image: url('../assets/images/image_2.jpg');
}
.image_3{
	background-image: url('../assets/images/image_3.jpg');
}
.image_4{
	background-image: url('../assets/images/image_4.jpg');
}
.image_5{
	background-image: url('../assets/images/image_5.jpg');
}
.image_6{
	background-image: url('../assets/images/image_6.jpg');
}
</style>