<template>
<div>
	<section class="ftco-section ftco-no-pt ftco-no-pb ftco-services-2 bg-light"  id="service-section">
			<div class="container">
        	<div class="row d-flex">
	        <div class="col-md-7 py-5">
	        	<div class="py-lg-5">
		        	<div class="row justify-content-center pb-5">
			          <div class="col-md-12 heading-section">
			            <h2 class="mb-3">Our Services</h2>
			          </div>
			        </div>
			        <div class="row">
			        	<div class="col-md-6 d-flex align-self-stretch ">
			            <div class="media block-6 services d-flex">
			              <div class="icon justify-content-center align-items-center d-flex"><span class="flaticon-ambulance"></span></div>
			              <div class="media-body pl-md-4">
			                <h3 class="heading mb-3">Emergency Services</h3>
			                <p>Reliable assistance during critical situations, ensuring immediate care and support.</p>
			              </div>
			            </div>      
			          </div>
			          <div class="col-md-6 d-flex align-self-stretch ">
			            <div class="media block-6 services d-flex">
			              <div class="icon justify-content-center align-items-center d-flex"><span class="flaticon-doctor"></span></div>
			              <div class="media-body pl-md-4">
			                <h3 class="heading mb-3">Outdoors Checkup</h3>
			                <p>Outdoors Checkup offers comprehensive health assessments and consultations.</p>
			              </div>
			            </div>      
			          </div>
			          <div class="col-md-6 d-flex align-self-stretch ">
			            <div class="media block-6 services d-flex">
			              <div class="icon justify-content-center align-items-center d-flex"><span class="flaticon-stethoscope"></span></div>
			              <div class="media-body pl-md-4">
			                <h3 class="heading mb-3">Qualified Doctors</h3>
			                <p>Access expert medical care with our team of qualified doctors.</p>
			              </div>
			            </div>      
			          </div>
			          <div class="col-md-6 d-flex align-self-stretch ">
			            <div class="media block-6 services d-flex">
			              <div class="icon justify-content-center align-items-center d-flex"><span class="flaticon-24-hours"></span></div>
			              <div class="media-body pl-md-4">
			                <h3 class="heading mb-3">24 Hours Service</h3>
			                <p>Experience uninterrupted 24-hour service catered to your needs.</p>
			              </div>
			            </div>      
			          </div>
			        </div>
			      </div>
		      </div>
		      <div class="col-md-5 d-flex">
	        	<div class="appointment-wrap bg-white p-4 p-md-5 d-flex align-items-center">
		        	<form ref="appointmentform" action="#" class="appointment-form ">
		        		<h3>Free Consultation</h3>
		    			<div class="">
			    		    <div class="form-group">
			    			    <input v-model="$v.typeform.name.$model" type="text" class="form-control" placeholder="Full Name">
                                <div v-if="$v.typeform.name.$error" class="text-danger">
                                    <span v-if="!$v.typeform.name.required">Please enter full name</span>
                                </div>
			    			</div>
			    			<div class="form-group">
                                <input v-model="$v.typeform.mobile_number.$model" type="mobile" class="form-control" placeholder="Mobile Number">
                                <div v-if="$v.typeform.mobile_number.$error" class="text-danger">
                                    <span v-if="!$v.typeform.mobile_number.required">Please enter mobile number</span>
                                    <span v-if="!$v.typeform.mobile_number.numeric">Please enter number only</span>
                                </div>
                            </div>
			    			<div class="form-group">
                                <input v-model="$v.typeform.age.$model" type="text" class="form-control" placeholder="Your Age" maxlength="3">
                                <div v-if="$v.typeform.age.$error" class="text-danger">
                                    <span v-if="!$v.typeform.age.required">Please enter age</span>
                                    <span v-if="!$v.typeform.age.numeric">Please enter number only</span>
                                </div>
                            </div>
			    			<div class="form-group">
                                <multiselect class="department"  v-model="selectedDepartment" :options="departments" :multiple="false"
                                    :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Please select department"
                                    :preselect-first="true" :showLabels="false" label="department_name"></multiselect>
                                <div v-if="$v.selectedDepartment.$error" class="text-danger">
                                    <span v-if="!$v.selectedDepartment.required">Please select department</span>
                                </div>
                            </div>
                            <div class="text-left">
                                <b-form-group label="Do you visiting us first time?" v-slot="{ ariaDescribedby }">
                                    <div class="row text-left ml-1">
                                        <div class="col-md-3">
                                            <b-form-radio v-model="isVisitingFirstTime" :aria-describedby="ariaDescribedby" value="Yes">Yes</b-form-radio>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-radio v-model="isVisitingFirstTime" :aria-describedby="ariaDescribedby" value="No">No</b-form-radio>
                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="form-group" v-if="isVisitingFirstTime == 'No'">
                                <input v-model="$v.typeform.patient_record_number.$model" type="text" class="form-control" placeholder="Patient Record Number">
                                <div v-if="patient_record_number_required" class="text-danger">
                                    <span>Please enter patient number</span>
                                </div>
                                <div v-else-if="$v.typeform.patient_record_number.$error" class="text-danger">
                                    <span v-if="!$v.typeform.patient_record_number.numeric">Please enter number only</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <datepicker class="datePicker"  v-model="appointmentDate" placeholder="Appointment Date" :disabled-dates="disabledDates" required></datepicker>
                                <div v-if="$v.appointmentDate.$error" class="text-danger">
                                    <span v-if="!$v.appointmentDate.required">Please select appointment date</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <vue-timepicker class="timePicker" v-model="appointmentTime" placeholder="Appointment Time" format="hh:mm A" :minute-interval="15" :hour-range="timeRange" close-on-complete hide-disabled-items></vue-timepicker>
                                <div v-if="$v.appointmentTime.$error" class="text-danger">
                                    <span v-if="!$v.appointmentTime.required">Please select appointment time</span>
                                </div>
                                <div v-if="time_format_wrong" class="text-danger">
                                    <span>Please select valid time</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input value="Book Appointment" class="btn btn-secondary py-3 px-5" @click="bookAppointmentAPI()">
                            </div>
		    			</div>
		    		</form>
		    	</div>
	        </div>
		    </div>
			</div>
	</section>
	    <section class="ftco-intro img" id="doctorDiv" hidden>
			<div class="overlay"></div>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-9 text-center">
						<h2>Your Health is Our Priority</h2>
						<p>We can manage your dream building A small river named Duden flows by their place</p>
						<p class="mb-0"><a href="#" class="btn btn-white px-4 py-3">Search Places</a></p>
					</div>
				</div>
			</div>
		</section>
			<section class="ftco-section ftco-no-pt ftco-no-pb" id="department-section">
    	<div class="container-fluid px-0">
    		<div class="row no-gutters">
    			<div class="col-md-4 d-flex">
    				<div class="img img-dept align-self-stretch department-section-next-div"></div>
    			</div>

    			<div class="col-md-8">
    				<div class="row no-gutters">
    					<div class="col-md-4">
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Neurology</a></h3>
    								<p>Expert care and advanced treatments tailored to neurological conditions at our Neurology Department.</p>
    							</div>
    						</div>
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Surgical</a></h3>
    								<p>Experience precision and expertise in our surgical department, dedicated to your health and well-being.</p>
    							</div>
    						</div>
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Dental</a></h3>
    								<p>Transforming smiles with precision and care at our dental department.</p>
    							</div>
    						</div>
    					</div>

    					<div class="col-md-4">
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Opthalmology</a></h3>
    								<p>Discover precision-focused eye care solutions with our dedicated Ophthalmology department.</p>
    							</div>
    						</div>
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Cardiology</a></h3>
    								<p>Experience advanced cardiac care tailored to your needs with our dedicated Cardiology department.</p>
    							</div>
    						</div>
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Traumatology</a></h3>
    								<p>Expert care and support for trauma patients, ensuring swift recovery and rehabilitation.</p>
    							</div>
    						</div>
    					</div>

    					<div class="col-md-4">
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Nuclear Magnetic</a></h3>
    								<p>Cutting-edge Nuclear Magnetic department for precise diagnostics and advanced medical imaging.</p>
    							</div>
    						</div>
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">X-ray</a></h3>
    								<p>Accurate diagnostics and rapid results: Transforming healthcare through our advanced X-ray department.</p>
    							</div>
    						</div>
    						<div class="department-wrap p-4 ">
    							<div class="text p-2 text-center">
    								<div class="icon">
    									<span class="flaticon-stethoscope"></span>
    								</div>
    								<h3><a href="#">Paramedical</a></h3>
    								<p>Empowering healthcare through skilled and dedicated paramedical professionals.</p>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </section>
	
</div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Swal from 'sweetalert2'
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Multiselect from 'vue-multiselect';
import moment from "moment";
import config from '../config';

export default {
  name: 'Department',
  components: {
      Datepicker,
      VueTimepicker,
      Multiselect,
  },
  data() {
    return {
        client_id: config.client_id,
        appointmentDate: '',
        appointmentTime: '',
        disabledDates: {
            days: [0],
            to: new Date()
        },
        timeRange: [11,12,13,14,15,16,17],
        isVisitingFirstTime: 'No',
        departments: [],
        selectedDepartment:null,
        patient_record_number_required:false,
        time_format_wrong:false,
        typeform:{
            name: '',
            mobile_number: '',
            age: '',
            patient_record_number: '',
        },
    }
  },
  validations: {
    typeform: {
        name: {required},
        mobile_number: {required, numeric},
        age: {required, numeric},
        patient_record_number:{numeric},
    },
    appointmentDate:{required},
    appointmentTime:{required},
    selectedDepartment:{required},
  },
  mounted(){
    this.getDepartmentsAPI()
  },
  methods:{
    ...mapActions("hospital",["getDepartments", "registerAppointment"]),

    bookAppointmentAPI(){
        this.patient_record_number_required = false;
        this.time_format_wrong = false;

        if (this.isVisitingFirstTime == "No" && (this.typeform.patient_record_number == null || this.typeform.patient_record_number == '')) {
            this.patient_record_number_required = true;
            return
        }
        this.$v.$touch();

        if(this.appointmentTime.includes('hh') || this.appointmentTime.includes('mm')) {
            this.time_format_wrong = true;
        }
		
		if (this.$v.typeform.$invalid) {
			return;
		}
        var bodyFormData = new FormData();
        bodyFormData.append('name', this.typeform.name);
        bodyFormData.append('mobile_number', this.typeform.mobile_number);
        bodyFormData.append('age', this.typeform.age);
        bodyFormData.append('is_first_time_visit', this.isVisitingFirstTime);
        bodyFormData.append('patient_number', this.typeform.patient_record_number);
        bodyFormData.append('date', moment(String(this.appointmentDate)).format('YYYY-MM-DD'));
        bodyFormData.append('time', this.convertTime12to24(this.appointmentTime));
        bodyFormData.append('client_id', this.client_id);
        this.registerAppointment(bodyFormData).then((response) => {
            if (response.response_code == 200) {
				this.typeform.name = "";
				this.typeform.mobile_number = "";
				this.typeform.age = "";
				this.isVisitingFirstTime = "No";
				this.patient_record_number = "";
				this.appointmentDate = "";
				this.appointmentTime = "";

                this.$refs.appointmentform.reset()
                Swal.fire({
                  title: '',
                  text: response.message,
                  icon: 'success',
                  confirmButtonText: 'Close'
                })
            } else{
                Swal.fire({
                  title: '',
                  text: response.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
            }
        });

    },

    getDepartmentsAPI(){
        this.departments = [];
        var bodyFormData = new FormData();
        bodyFormData.append('client_id', this.client_id);
        this.getDepartments(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.departments = response.data;
            }
        });
    },

    convertTime12to24(time12h) {
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    }

  }

}
</script>
<style>
.department-section-next-div{
background-image: url('../assets/images/dept-1.jpg');
}
#doctorDiv{
	  background-image:url('../assets/images/bg_2.jpg')
}
.SecondSectionDiv{
  background-image:url('../assets/images/about.jpg')
}
.multiselect__tags{
	border-radius:30px !important;
}
.datePicker input{
	width:100%;
	border-radius: 30px;
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
	padding:10px;
}
.form-group .timePicker{
	width: 100% !important;
	padding: 10px
}
.timePicker input {
	
	border-radius: 30px;
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
	padding:10px;
}
.vue__time-picker input.display-time {
	width:100% !important;
	padding: 10px !important;
	height: 50px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
