<template>
<div>
	<section class="ftco-counter img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
    	<div class="container">
    		<div class="row d-flex">
    			<div class="col-md-6 col-lg-5 d-flex">
    				<div class="img d-flex align-self-stretch align-items-center SecondSectionDiv">
    				</div>
    			</div>
    			<div class="col-md-6 col-lg-7 pl-lg-5 py-md-5">
    				<div class="py-md-5">
	    				<div class="row justify-content-start pb-3">
			          <div class="col-md-12 heading-section p-4 p-lg-5">
			            <h2 class="mb-4">We Are <span><i>Softpital</i></span><br/>Making Hospital Management Solutions</h2>
			            <p>Connect with us to learn more about how Softpital is revolutionizing hospital management solutions. Reach out to us to learn more about our innovative software offerings and how we can optimize your healthcare facility.</p>
			            <p>
							<!-- <a href="#" class="btn btn-primary py-3 px-4">Make an appointment</a>  -->
							<a href="#contact-section" class="btn btn-secondary py-3 px-4">Contact us</a></p>
			          </div>
			        </div>
		        </div>
	        </div>
        </div>
    	</div>
    </section>
</div>
</template>

<script>
export default {
  name: 'About',
  
}
</script>
<style>
.SecondSectionDiv{
  background-image:url('../assets/images/about.jpg')
}
</style>