<template>
<div>
   <section class="ftco-section contact-section" id="contact-section">
      <div class="container">
      	<div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 heading-section text-center ">
            <h2 class="mb-4">Contact Us</h2>
            <p>For inquiries regarding <i>Sofpital's</i> Hospital Management Solutions, feel free to contact us using the information provided below. We're here to address your needs and discuss how our solutions can optimize your healthcare facility.</p>
          </div>
        </div>
        <div class="row d-flex contact-info mb-5">
          <div class="col-md-6 col-lg-3 d-flex ">
          	<div class="align-self-stretch box p-4 text-center bg-light">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-map-signs"></span>
          		</div>
          		<h3 class="mb-4">Address</h3>
	            <p>Ahmedabad, Gujarat, India</p>
	          </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex ">
          	<div class="align-self-stretch box p-4 text-center bg-light">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-phone2"></span>
          		</div>
          		<h3 class="mb-4">Contact Number</h3>
	            <p><a href="tel://7383543849">+91 7383543849</a></p>
	          </div>
          </div>
          <div class="col-md-6 col-lg-3 d-flex ">
          	<div class="align-self-stretch box p-4 text-center bg-light">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-paper-plane"></span>
          		</div>
          		<h3 class="mb-4">Email Address</h3>
	            <p><a href="mailto:support@softpital.in">support@softpital.in</a></p>
	          </div>
          </div>
          <!-- <div class="col-md-6 col-lg-3 d-flex ">
          	<div class="align-self-stretch box p-4 text-center bg-light">
          		<div class="icon d-flex align-items-center justify-content-center">
          			<span class="icon-globe"></span>
          		</div>
          		<h3 class="mb-4">Website</h3>
	            <p><a href="#">yoursite.com</a></p>
	          </div>
          </div> -->
        </div>
        <div class="row no-gutters block-9">
          <div class="col-md-6 order-md-last d-flex">
            <form ref="contactform" action="#" class="bg-light p-5 contact-form">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Your Name" v-model="$v.typeform.name.$model">
                <div v-if="$v.typeform.name.$error" class="text-danger">
                    <span v-if="!$v.typeform.name.required">Please enter your name</span>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Your Email" v-model="$v.typeform.email.$model">
                <div v-if="$v.typeform.email.$error" class="text-danger">
                    <span v-if="!$v.typeform.email.required">Please enter your email</span>
                    <span v-if="!$v.typeform.email.email">Please enter valid email address</span>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Subject" v-model="$v.typeform.subject.$model">
                <div v-if="$v.typeform.subject.$error" class="text-danger">
                    <span v-if="!$v.typeform.subject.required">Please enter subject</span>
                </div>
              </div>
              <div class="form-group">
                <textarea name="" id="" cols="30" rows="7" class="form-control" placeholder="Message" v-model="$v.typeform.message.$model"></textarea>
                <div v-if="$v.typeform.message.$error" class="text-danger">
                    <span v-if="!$v.typeform.message.required">Please enter message</span>
                </div>
              </div>
              <div class="form-group">
                <input value="Send Message" class="btn btn-secondary py-3 px-5" @click="registerContactInfoAPI()">
              </div>
            </form>
          
          </div>

          <div class="col-md-6 d-flex">
          	<div id="map" class="bg-white"></div>
          </div>
        </div>
      </div>
    </section>

</div>
</template>

<script>
let instance;

import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Swal from 'sweetalert2'
import config from '../config';

export default {
  name: 'Contact',
  data() {
    return {
        name: '',
        email: '',
        subject: '',
        message: '',
        typeform:{
            name:'',
            email:'',
            subject:'',
            message:'',
            client_id:config.client_id,
        },
    }
  },
  validations: {
    typeform: {
        name:{required},
        email:{required, email},
        subject:{required},
        message:{required},
        client_id:{required},
    },
  },
  mounted(){   },
  methods:{
    ...mapActions("hospital",["registerContactInfo"]),

    registerContactInfoAPI(){
        this.$v.$touch();
        var bodyFormData = new FormData();
        bodyFormData.append('name', this.typeform.name);
        bodyFormData.append('email', this.typeform.email);
        bodyFormData.append('client_id', this.typeform.client_id);
        bodyFormData.append('subject', this.typeform.subject);
        bodyFormData.append('message', this.typeform.message);
        this.registerContactInfo(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.$refs.contactform.reset()
                Swal.fire({
                  title: '',
                  text: response.message,
                  icon: 'success',
                  confirmButtonText: 'Close'
                })
            } else{
                Swal.fire({
                  title: '',
                  text: response.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
            }
        });
      },
    }
}
</script>
<style>
.SecondSectionDiv{
  background-image:url('../assets/images/about.jpg')
}
</style>