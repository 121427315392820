<template>
<div>
	<Menu/>
    <section class="hero-wrap js-fullheight firstSection" data-section="home" data-stellar-background-ratio="0.5">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-start" data-scrollax-parent="true">
          <div class="col-md-6 pt-5">
          	<div class="mt-5">
          		<span class="subheading">Welcome to the </span>
	            <h1 class="mb-4"><i>Softpital</i> Demo Website!</h1>
	            <p class="mb-4">We're dedicated to providing top-notch hospital management software solutions. Explore our demo to experience how we can streamline your hospital's operations and enhance patient care.</p>
	            <!-- <p><a href="#" class="btn btn-primary py-3 px-4">Make an appointment</a></p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
	<About />
	<Department />
	<Doctor />
  <Contact />
  <Footer />
</div>
</template>

<script>
import Menu from './layout/Menu.vue'
import About from './About.vue'
import Department from './Department.vue'
import Doctor from './Doctor.vue'
import Contact from './Contact.vue'
import Footer from './layout/Footer.vue'
export default {
  name: 'Dashboard',
   components: {
    Menu,
	About,
	Department,
	Doctor,
  Contact,
  Footer
  }
}
</script>
<style>
.firstSection{
  background-image: url('../assets/images/bg_3.jpg')
}
.SecondSectionDiv{
  background-image:url('../assets/images/about.jpg')
}
</style>