import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import '@/assets/css/style.css';
import '@/assets/css/open-iconic-bootstrap.min.css';
import '@/assets/css/animate.css';
import '@/assets/css/owl.carousel.min.css';
import '@/assets/css/owl.theme.default.min.css';
import '@/assets/css/magnific-popup.css';
import '@/assets/css/aos.css';
import '@/assets/css/ionicons.min.css';
import '@/assets/css/flaticon.css';
import '@/assets/css/icomoon.css';
import Vuelidate from "vuelidate";
import store from './store'
import axios from 'axios';
import config from './config';

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
window.axios = axios;
Vue.prototype.$store = store

window.axios.defaults.baseURL = config.api_url;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

new Vue({
    store,
  render: h => h(App),
}).$mount('#app')
