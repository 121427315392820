import hospitalApi from '../apis/hospital';

const state = {};
const actions = {    
    registerContactInfo ({ commit }, data) {
        return hospitalApi.registerContactInfo(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    registerAppointment({ commit }, data) {
        return hospitalApi.registerAppointment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getDepartments({ commit }, data) {
        return hospitalApi.getDepartments(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
};
const mutations = {};
const getters = {};
export const hospital = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};