<template>
<div class="footerimg">
  <footer class="ftco-footer ftco-section img " >
    	<div class="overlay "></div>
      <div class="container-fluid px-md-5">
        <div class="row mb-5">
          <div class="col-md">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2">Softpital</h2>
              <p>Providing top-notch hospital management software solutions.</p>
              <ul class="ftco-footer-social list-unstyled mt-5">
                <!-- <li class=""><a href="#"><span class="icon-twitter"></span></a></li> -->
                <!-- <li class=""><a href="#"><span class="icon-facebook"></span></a></li> -->
                <li class=""><a href="https://instagram.com/_u/softpital"><span class="icon-instagram"></span></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md">
            <div class="ftco-footer-widget mb-4 ml-md-4">
              <h2 class="ftco-heading-2 text-left">Departments</h2>
              <ul class="list-unstyled text-left">
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Neurology</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Opthalmology</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Nuclear Magnetic</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Surgical</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Cardiology</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Dental</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md">
            <div class="ftco-footer-widget mb-4 ml-md-4">
              <h2 class="ftco-heading-2 text-left">Links</h2>
              <ul class="list-unstyled text-left">
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Home</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>About</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Departments</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Doctors</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Contact</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md">
             <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Services</h2>
              <ul class="list-unstyled text-left">
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Emergency Services</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Qualified Doctors</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>Outdoors Checkup</a></li>
                <li><a href="#"><span class="icon-long-arrow-right mr-2"></span>24 Hours Services</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md">
            <div class="ftco-footer-widget mb-4">
            	<h2 class="ftco-heading-2 text-left">Have a Questions?</h2>
            	<div class="block-23 mb-3 text-left">
	              <ul>
	                <li><span class="icon icon-map-marker"></span><span class="text">Ahmedabad,Gujarat, India</span></li>
	                <li><a href="#"><span class="icon icon-phone"></span><span class="text">+91 7383543849</span></a></li>
	                <li><a href="#"><span class="icon icon-envelope pr-4"></span><span class="text">mili.shah22@gmail.com</span></a></li>
	              </ul>
	            </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters d-flex align-items-start align-items-center px-md-0">
      	    <div class="col-lg-12 d-block">
      		    <span class="text text-danger">Please note that the images and names displayed are solely for demonstration purposes. There is no real connection between the depicted doctors and patients. We respect confidentiality and privacy in all our representations.</span>
      	    </div>
      	</div>
      </div>
    </footer>
    
</div>
</template>

<script>
export default {
  name: 'Footer'
 
}
</script>
<style>
.footerimg{
  background-image:url("../../assets/images/footer-bg.jpg")
}
</style>